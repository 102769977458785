@import './theme/variables.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import './mixin.scss';

body {
    &:has(.page-header) {
        padding-top: 80px;
        @include media(md) {
            padding-top: 72px;
        }
    }
}
img {
    max-width: 100%;
    height: auto;
}
// Container css
.container {
    padding: 0 16px;
    max-width: 1232px;
    margin: 0 auto;
    width: 100%;
}

// Typography css
@include generate-font-size();
.text {
    &-black {
        color: rgb(var(--text-black));
    }
    &-white {
        color: rgb(var(--text-white));
        &-300 {
            color: rgba(var(--text-white), 0.3);
        }
        &-500 {
            color: rgba(var(--text-white), 0.5);
        }
        &-600 {
            color: rgba(var(--text-white), 0.6);
        }
    }
    &-gray {
        &-200 {
            color: rgba(var(--gray-200));
        }
        &-300 {
            color: rgba(var(--gray-300));
        }
        &-500 {
            color: rgba(var(--gray-500));
        }
        &-700 {
            color: rgba(var(--gray-700));
        }
    }
    &-slate-gray {
        color: rgb(var(--slate-gray));
    }
    &-spanish-gray {
        color: rgb(var(--spanish-gray));
    }
}
.heading {
    &-46 {
        font-size: 46px;
        font-weight: 800;
        line-height: 56.07px;
        @include media(md) {
            font-size: 40px;
            line-height: 48px;
        }
        @include media(sm) {
            font-size: 36px;
            line-height: 44px;
        }
    }
    &-30 {
        margin: 0;
        font-size: 30px;
        font-weight: 700;
        line-height: 45px;
        letter-spacing: -0.5px;
        @include media(md) {
            font-size: 24px;
            line-height: 32px;
        }
    }
    &-29 {
        font-size: 29px;
        font-weight: 700;
        line-height: 35.35px;
        letter-spacing: 0.02em;
        @include media(md) {
            font-size: 24px;
            line-height: 32px;
        }
    }
    &-20 {
        margin: 0;
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        @include media(md) {
            font-size: 18px;
            line-height: 24px;
        }
    }
    &-18 {
        margin: 0;
        font-size: 18px;
        font-weight: 700;
        line-height: 26px;
        @include media(md) {
            font-size: 16px;
            line-height: 24px;
        }
    }
}
.paragraph {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    @include media(md) {
        font-size: 15px;
        line-height: 21px;
    }
    &-sm {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        @include media(md) {
            font-size: 12px;
            line-height: 18px;
        }
    }
    &-lg {
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        @include media(md) {
            font-size: 16px;
            line-height: 24px;
        }
    }
}
.fw {
    &-300 {
        font-weight: 300;
    }
    &-500 {
        font-weight: 500;
    }
    &-600 {
        font-weight: 600;
    }
    &-700 {
        font-weight: 600;
    }
}
.text {
    &-primary-link {
        color: rgb(var(--primary-color));
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    &-secondary-link {
        color: rgb(var(--secondary-500));
        text-decoration: none;
        display: inline-flex;
        gap: 8px;
        align-items: center;
        &:hover {
            text-decoration: underline;
            color: rgb(var(--secondary-600));
        }
    }
}
.ant-typography {
    &-ellipsis {
        .ant-typography-expand {
            color: transparent;
            font-size: 0px;
            &::after {
                content: 'Read More';
                color: rgb(var(--primary-color));
                font-size: 14px;
            }
        }
    }
}

// Line Height Css
.lh {
    &-20 {
        line-height: 20px;
    }
    &-24 {
        line-height: 24px;
    }
    &-28 {
        line-height: 28px;
    }
}

// Spacing Css
@include generate-spacing-classes(margin, m, $breakpoints);
@include generate-spacing-classes(padding, p, $breakpoints);

// icon css
.icon {
    display: inline-flex;
    &.s-24 {
        font-size: 24px;
    }
    &.s-16 {
        font-size: 16px;
    }
    &.s-20 {
        font-size: 20px;
    }
    svg {
        display: block;
    }
}

// align css
.text-center {
    text-align: center;
}
.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}

// size
.h {
    &-full {
        height: 100%;
    }
}

// Ant Button CSS
a.ant-btn {
    padding-block: 2px !important;
}
.ant-btn {
    &.ant-btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 12px;
        letter-spacing: 0.3px;
        &:hover {
            .right-arrow-icon {
                transform: translateX(5px);
            }
            .left-arrow-icon {
                transform: translateX(-5px);
            }
        }
        &-primary {
            &:not(.ant-btn-background-ghost):hover,
            &:not(.ant-btn-background-ghost):focus {
                background-color: rgb(var(--primary-color-hover));
                border-color: rgb(var(--primary-color-hover));
            }
            &[disabled] {
                background-color: rgb(var(--primary-color-disabled));
                border-color: rgb(var(--primary-color-disabled));
                color: rgb(var(--btn-primary-color));
                &:hover,
                &:focus,
                &:active {
                    background-color: rgb(var(--primary-color-disabled));
                    border-color: rgb(var(--primary-color-disabled));
                    color: rgb(var(--btn-primary-color));
                }
            }
        }
        &.btn-icon {
            height: 40px;
            width: 40px;
            padding: 8px;
            &:hover {
                border-color: transparent;
                color: rgb(var(--primary-color));
            }
            &-only {
                padding: 0;
                height: auto;
                width: auto;
                border: 0px;
                background-color: transparent;
                &.ant-btn-primary {
                    color: rgb(var(--primary-color));
                    &:hover,
                    &:focus {
                        color: rgb(var(--primary-color-hover));
                    }
                    &[disabled] {
                        background-color: transparent;
                        border: 0px;
                        color: rgb(var(--primary-color-disabled));
                        &:hover,
                        &:focus,
                        &:active {
                            color: rgb(var(--primary-color-disabled));
                        }
                    }
                }
            }
        }
        .right-arrow-icon,
        .left-arrow-icon {
            transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
        &-lg {
            border-radius: 16px;
            letter-spacing: 0px;
            @include media(sm) {
                padding: 13.4px 24px;
            }
        }
        &-default {
            &.ant-btn-background-ghost {
                &:hover,
                &:focus {
                    border-color: rgb(var(--gray-100));
                    color: rgb(var(--gray-100));
                }
            }
            &.default-gray {
                border-radius: 90px;
                font-weight: 700;
                border: 2px solid rgb(var(--border-color-split));
                &:hover,
                &:focus {
                    border-color: rgb(var(--border-color-split));
                    background-color: rgba(var(--border-color-split), 0.5);
                    color: rgb(var(--text-white));
                    .icon {
                        color: rgb(var(--text-white));
                    }
                }
                .icon {
                    color: rgb(var(--slate-gray));
                }
            }
        }
    }
}
// Input Css
.ant-input-affix-wrapper {
    > .ant-input {
        border-radius: 0px;
        backdrop-filter: blur(0px);
    }
    .ant-input-prefix {
        margin-right: 10px;
    }
}
.ant-input {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    border-radius: 15px;
    backdrop-filter: blur(5px);
    &textarea & {
        resize: none;
    }
}

// Divider Css
.ant-divider {
    &-horizontal {
        &.ant-divider-with-text {
            margin: 32px 0;
            font-size: 12px;
            font-weight: 700;
            line-height: 20px;
            color: rgb(var(--blue-bayoux));
            @include media(md) {
                margin: 20px 0;
            }
        }
    }
    &-inner-text {
        padding: 6px 9px;
        border: 1px solid rgb(var(--input-border-color));
        border-radius: 50%;
        background-color: rgb(var(--dark-gray));
    }
}

// ant badge Css
.ant-badge-dot {
    background-color: rgb(var(--primary-color));
    box-shadow: inherit;
    transform: translate(0);
    transform-origin: 0%;
}

// Dropdown Css
.ant-dropdown {
    &-menu {
        border: 1px solid rgb(var(--ebony-black-two));
        border-radius: 8px;
        box-shadow:
            0px 4px 6px -2px rgba(var(--ebony-black-three), 0.03),
            0px 12px 16px -4px rgba(var(--ebony-black-three), 0.08);
        &-item {
            &:hover {
                background-color: rgba(var(--silver), 0.1);
            }
            .ant-menu-title-content {
                display: flex;
                align-items: center;
                gap: 8px;
            }
        }
        &-item-divider {
            background-color: rgb(var(--ebony-black-two));
        }
    }
}

// Select Css
.ant-select {
    font-weight: 500;
    &-dropdown {
        border: 1px solid rgb(var(--ebony-black-two));
        border-radius: 8px;
        box-shadow:
            0px 4px 6px -2px rgba(var(--ebony-black-three), 0.03),
            0px 12px 16px -4px rgba(var(--ebony-black-three), 0.08);
    }
    &.ant-select-multiple {
        .ant-select-selection-search {
            margin: 0;
            &-input {
                height: 20px;
                line-height: 20px;
            }
        }
        .ant-select-selector {
            padding: 7px 14px;
            row-gap: 10px;
            min-height: 60px;
            &::after {
                margin: 0;
                line-height: 20px;
            }
        }
        .ant-select-selection-item {
            border-radius: 12px;
            border: 0px;
            font-weight: 500;
            padding: 0px 12px;
            margin: 0 10px 0 0;
            &-content {
                margin-right: 10px;
            }
            &-remove {
                color: rgb(var(--text-white));
            }
        }
    }
}

// Form Css
.ant-form {
    &-item-label {
        font-weight: 500;
        line-height: 22px;
    }
}

// Modal Css
.ant-modal {
    max-width: calc(100vw - 32px);
    margin: 16px auto;
    &-close {
        display: none;
    }
    &-content {
        margin-block: 20px;
        border-radius: 32px;
        overflow: hidden;
        border: 1px solid rgb(var(--border-color-split));
    }
    &-header {
        border: 0px;
        @include media(md) {
            padding: 16px 16px 16px 16px;
        }
        .ant-modal-title {
            font-weight: 700;
            @include media(md) {
                font-size: 24px;
                line-height: 30px;
            }
            @include media(sm) {
                font-size: 20px;
                line-height: 28px;
            }
        }
    }
    &-body {
        @include media(md) {
            padding: 20px 16px;
        }
    }
    &-footer {
        @include media(md) {
            padding: 16px;
        }
        &.modal--foot-submit {
            margin: 40px -32px -40px;
            @include media(md) {
                margin: 20px -16px -20px;
            }
        }
    }
    &.full-screen-modal {
        height: 100vh;
        margin: 0;
        max-width: 100%;
        .ant-modal-content {
            margin: 0;
            height: 100vh;
            border-radius: 0px;
            .ant-modal-body {
                height: calc(100vh - 107px);
                overflow: auto;
                @include media(md) {
                    height: calc(100vh - 62px);
                }
                > div {
                    height: 100%;
                    .modal-body-wrap {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                    }
                }
            }
            .ant-modal-footer {
                margin-top: auto;
                position: sticky;
                bottom: -40px;
                @include media(md) {
                    bottom: -20px;
                }
            }
        }
    }
}

// Datepicker Css
.ant-picker {
    width: 100%;
    &-panel-container {
        border: 1px solid rgb(var(--ebony-black-two));
        border-radius: 8px;
        box-shadow:
            0px 4px 6px -2px rgba(var(--ebony-black-three), 0.03),
            0px 12px 16px -4px rgba(var(--ebony-black-three), 0.08);
    }
    &-header {
        > button {
            color: rgb(var(--gray-500));
        }
    }
    &-cell {
        color: rgb(var(--gray-500));
        &-in-view {
            color: rgb(var(--text-white));
        }
        &-selected {
            &.ant-picker-cell-in-view {
                .ant-picker-cell-inner {
                    color: rgb(var(--btn-primary-color));
                }
            }
        }
    }
    &-suffix {
        color: rgb(var(--text-white));
    }
}

// Checkbox Css
.ant-checkbox-wrapper {
    align-items: center;
    font-weight: 500;
    .ant-checkbox {
        top: 0;
        + span {
            padding-left: 12px;
        }
    }
}

// Tag Css
.ant-tag {
    padding: 8px 12px;
    margin: 0;
    &.tag {
        &-primary {
            border: 1px solid rgb(var(--primary-color));
            background-color: rgba(var(--primary-color), 0.1);
            color: rgb(var(--yellow-400));
        }
        &-secondary {
            border: 1px solid rgb(var(--secondary-500));
            background-color: rgba(var(--secondary-500), 0.1);
            color: rgb(var(--secondary-500));
        }
        &-green {
            border: 1px solid rgb(var(--positive-500));
            background-color: rgba(var(--positive-500), 0.1);
            color: rgb(var(--positive-400));
        }
    }
}

// Rateing Star Css
.ant-rate {
    display: flex;
    align-items: center;
    gap: 4px;
    .ant-rate {
        &-star {
            margin: 0px;
            .anticon {
                display: block;
            }
        }
    }
}
// File Upload CSS
.ant-upload {
    width: 100%;
}
// Auth Pages Css
.auth-wrapper {
    overflow: hidden;
    min-height: 100vh;
    background: url('/assets/images/sign-up-bg.jpg') no-repeat center;
    background-size: cover;
    position: relative;
    .auth-page-pattern {
        background-image: url('/assets/images/auth-pattern-tl.svg');
        background-repeat: no-repeat;
        background-size: contain;
        width: 27px;
        height: 40px;
        position: absolute;
        top: 40px;
        left: 40px;
        @include media(sm) {
            top: 12px;
            left: 12px;
        }
        &.top-right {
            background-image: url('/assets/images/auth-pattern-tr.svg');
            right: 40px;
            left: auto;
            @include media(sm) {
                right: 12px;
            }
        }
        &.bottom-left {
            background-image: url('/assets/images/auth-pattern-bl.svg');
            top: auto;
            bottom: 40px;
            @include media(sm) {
                bottom: 12px;
            }
        }
        &.bottom-right {
            background-image: url('/assets/images/auth-pattern-br.svg');
            top: auto;
            bottom: 40px;
            right: 40px;
            left: auto;
            @include media(sm) {
                bottom: 12px;
                right: 12px;
            }
        }
    }
}
