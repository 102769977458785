.default {
  --primary-color: 255, 202, 0;
  --primary-color-hover: 232, 184, 0;
  --primary-color-disabled: 107, 85, 0;
  --btn-primary-color: 25, 25, 30;
  --body-background: 20, 20, 22;
  --text-black: 0, 0, 0;
  --text-white: 253, 253, 253;
  --light-purple: 202, 204, 238;
  --input-border-color: 49, 71, 88;
  --dark-gray: 34, 35, 50;
  --blue-bayoux: 80, 104, 123;
  --blue-bayoux-one: 123, 145, 158;
  --bg-white: 255, 255, 255;
  --slate-gray: 119, 126, 144;
  --rich-black: 5, 3, 29;
  --ebony-black: 12, 17, 29;
  --ebony-black-two: 31, 36, 47;
  --ebony-black-three: 16, 24, 40;
  --silver: 206, 207, 210;
  --spanish-gray: 148, 150, 156;
  --modal-bg-black: 32, 34, 41;
  --ebony-black-four: 45, 46, 56;
  --lavender: 232, 229, 255;
  --secondary-500: 242, 65, 138;
  --secondary-600: 220, 59, 126;
  --input-placeholder-color: 99, 101, 110;
  --black-card-bg: 35, 38, 47;
  --border-color-split: 53, 57, 69;
  --gray-100: 204, 209, 212;
  --gray-200: 179, 186, 191;
  --gray-300: 144, 155, 161;
  --gray-500: 89, 105, 115;
  --gray-600: 147, 150, 156;
  --gray-700: 137, 137, 138;
  --gray-750: 63, 75, 82;
  --gray-800: 46, 50, 61;
  --gray-850: 49, 58, 63;
  --modal-footer-bg: 35, 37, 43;
  --smoky-black: 15, 15, 15;
  --positive-400: 61, 220, 106;
  --positive-500: 65, 242, 115;
  --yellow-400: 239, 192, 10;
}
.dark {
  --body-background: 32, 34, 41;
}
