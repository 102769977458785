$spacing-scale: (
    0: 0px,
    6: 6px,
    8: 8px,
    16: 16px,
    18: 18px,
    20: 20px,
    24: 24px,
    40: 40px,
);
$font-size: (
    16: 16px,
    24: 24px,
    20: 20px
);
$breakpoints: (
    xs: 575px,
    sm: 767px,
    md: 991px,
    lg: 1199px
);
@mixin generate-spacing-classes($property, $short-name, $breakpoints: null) {
    @each $key, $value in $spacing-scale {
        .#{$short-name}-#{$key} {
            #{$property}: #{$value};
        }
        .#{$short-name}t-#{$key} {
            #{$property}-top: #{$value};
        }
        .#{$short-name}r-#{$key} {
            #{$property}-right: #{$value};
        }
        .#{$short-name}b-#{$key} {
            #{$property}-bottom: #{$value};
        }
        .#{$short-name}l-#{$key} {
            #{$property}-left: #{$value};
        }
        .#{$short-name}x-#{$key} {
            #{$property}-left: #{$value};
            #{$property}-right: #{$value};
        }
        .#{$short-name}y-#{$key} {
            #{$property}-top: #{$value};
            #{$property}-bottom: #{$value};
        }
    }
    @if $breakpoints != null {
        @each $breakpoint, $breakpoint-value in $breakpoints {
            @media screen and (max-width: #{$breakpoint-value}) {
                @each $key, $value in $spacing-scale {
                    .#{$short-name}-#{$breakpoint}-#{$key} {
                        #{$property}: #{$value};
                    }
                    .#{$short-name}t-#{$breakpoint}-#{$key} {
                        #{$property}-top: #{$value};
                    }
                    .#{$short-name}r-#{$breakpoint}-#{$key} {
                        #{$property}-right: #{$value};
                    }
                    .#{$short-name}b-#{$breakpoint}-#{$key} {
                        #{$property}-bottom: #{$value};
                    }
                    .#{$short-name}l-#{$breakpoint}-#{$key} {
                        #{$property}-left: #{$value};
                    }
                    .#{$short-name}x-#{$breakpoint}-#{$key} {
                        #{$property}-left: #{$value};
                        #{$property}-right: #{$value};
                    }
                    .#{$short-name}y-#{$breakpoint}-#{$key} {
                        #{$property}-top: #{$value};
                        #{$property}-bottom: #{$value};
                    }
                }
            }
        }
    }
}

@mixin generate-font-size() {
    @each $key, $value in $font-size {
        .fs-#{$key} {
            font-size: #{$value} !important;
        }
    }
}

@mixin media($breakpoint) {
    $mix-width: map-get($breakpoints, $breakpoint);

    @if $mix-width {
        @media (max-width: #{$mix-width}) {
            @content;
        }
    } @else {
        @warn "Breakpoint #{$breakpoint} not found in the map.";
    }
}
